<template>
    <!-- 导航 -->
    <page-head title="训练计划详情" />
    <content-bg>
        <template v-slot:content>
            <div class="mian">
                <!-- 头部 -->
                <div class="head">
                    <!-- 标题 -->
                    <div class="info">
                        <div class="icon_box">
                            <img class="icon" src="~@i//schedule/title.svg" />
                            <p>{{ palnData.title }}</p>
                        </div>
                    </div>
                    <!-- 时间 -->
                    <div class="info">
                        <div class="icon_box ml">
                            <img class="icon" src="~@i//schedule/date.svg" />
                            <p>{{palnData.start}} - {{palnData.end}}</p>
                        </div>
                    </div>
                    <!-- 教练组 -->
                    <div class="info">
                        <div class="icon_box">
                            <img class="icon" src="~@i//schedule/coach.svg" />
                            <div class="content">
                                <div v-for="item in palnData.coaching" class="avatar_box">
                                    <img :src="item.avatar" />
                                    <p>{{ item.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 场地 -->
                    <div class="info">
                        <div class="icon_box">
                            <img class="icon" src="~@i//schedule/address.svg" />
                            <p>{{ palnData.pitch || defaultText }}</p>
                        </div>
                    </div>
                    <!-- 内容 -->
                    <div class="info">
                        <div class="icon_box">
                            <img class="icon" src="~@i//schedule/content.svg" />
                            <p><pre>{{ palnData.desc || defaultText }}</pre></p>
                        </div>
                    </div>
                </div>
                <div v-if="exercisesData.showList && exercisesData.showList.length > 0" class="exercise_mian">
                    <div class="exercise_item">
                        <exercise-item
                            v-for="(item, index) in exercisesData.showList"
                            :key="item.id"
                            :item="item"
                            :index="index"
                            @click="details(item)" />
                    </div>
                    <div class="btn">
                        <div class="prev" @click="prevClick">
                            <img src="~@i/schedule/prev.svg" />
                        </div>
                        <div class="next" @click="nextClick">
                            <img src="~@i/schedule/next.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-bg>
    <!-- 翻页 -->
    <pagination-com class="pagination" :current-page="query.page" :total-page="query.totalPage" @changePage="changePage"></pagination-com>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const { proxy } = getCurrentInstance();
        const router = useRouter()
        const store = useStore();
        const { workItem, activeItem } = store.state.planData
        const { color } = proxy.$Config.UI_CONFIG
        const state = reactive({
            color,
            defaultText: '-',
            workItem, // vuex数据
            palnData: {}, // 页面显示数据
            query: {
                page: 1,
                totalPage: workItem.length
            },
            exercisesData: {}, // 教案数据
            exercisesPage: 1, // 教案翻页
        });

        // 页面翻页
        const changePage = (page) => {
            const data = workItem[page - 1]
            state.palnData = data
            state.query.page = page

            state.exercisesPage = 1
            exercisesPageFn()
        }

        // 上一页
        const prevClick = async () => {
            if(state.exercisesPage <= 1) return
            state.exercisesPage --
            exercisesPageFn()
        };

        // 下一页
        const nextClick = async () => {
            if(state.exercisesPage >= state.exercisesData.totalPage) return
            state.exercisesPage ++
            exercisesPageFn()
        };

        // 查询教案数据
        const exercisesPageFn = () => {
            console.log(state.exercisesData)
            state.exercisesData = proxy.$utils.paginationFn(state.palnData.exercises, state.exercisesPage, 4);
        }

        const details = (row) => {
            router.push({
                path: '/exerciseList/details',
                query: {
                    id: row.id,
                },
            })
        }

        onMounted(() => {
            state.palnData = workItem[0]
            state.query.totalPage = workItem.length
            exercisesPageFn()
        })

        return {
            changePage,
            prevClick,
            nextClick,
            details,
            ...toRefs(state),
        }
    }
}
</script>

<style scoped lang="scss">
.mian {
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .head {
        .info {
            display: flex;
            margin-bottom: 34px;
            .icon_box {
                display: flex;
                .icon {
                    margin-top: 4px;
                    margin-right: 16px;
                    width: 26px;
                    height: 26px;
                }
                p {
                    flex: 1;
                    font-weight: 400;
                    font-size: 18px;
                    color: #fff;
                    line-height: 32px;
                    word-break: break-all;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .content {
                    display: flex;
                    .avatar_box {
                        min-width: 100px;
                        display: flex;
                        align-items: center;
                        margin-right: 30px;
                        img {
                            margin-right: 10px;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                        p {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    .exercise_mian {
        margin-top: 40px;
        display: flex;
        .exercise_item {
            flex: 1;
            display: grid;
            grid-template-columns: repeat(4, 272px);
            grid-template-rows: auto;
            justify-content: space-between;
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            width: 120px;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                box-sizing: border-box;
                cursor: pointer;
                img {
                    width: 14.39px;
                }
            }
            .prev {
                margin-bottom: 32px;
                background-color: #fff;
            }
            .next {
                background: #714CC0;
            }
        }
    }
}
.pagination {
    position: absolute;
    z-index: 1;
    top: 1000px;
    left: 590px;
}
</style>